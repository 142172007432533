/* You can add global styles to this file, and also import other style files */
//@import "~@ng-select/ng-select/themes/default.theme.css";
//@import '~open-iconic/font/css/open-iconic-bootstrap.css';
$dark-color: #1c1c1c;
$white-color: #fff;
$secondary-color: #55b9b0;
$primary-color : #FCC576;
$red-color:  #db7464;

.height-100 {
  height: 100%;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";


//@import "~material-design-icons/iconfont/material-icons.css";
@media print {
  @page {
    size: auto !important;
  }
}
body {
  background-color: #f5f7fa;
}
a {
  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
}
.svg-inline--fa {
  font-size: 22px;
}

.text-primary{
  color : $primary-color !important;
}

.text-secondary{
  color : $secondary-color !important;
}

.text-red{
  color : $red-color !important;
}

// .material-icons {
//   font-family: "Material Icons";
//   font-weight: normal;
//   font-style: normal;
//   font-size: 24px;
//   /* Preferred icon size */
//   display: inline-block;
//   line-height: 1;
//   text-transform: none;
//   letter-spacing: normal;
//   word-wrap: normal;
//   white-space: nowrap;
//   direction: ltr;
//   /* Support for all WebKit browsers. */
//   -webkit-font-smoothing: antialiased;
//   /* Support for Safari and Chrome. */
//   text-rendering: optimizeLegibility;
//   /* Support for Firefox. */
//   -moz-osx-font-smoothing: grayscale;
//   /* Support for IE. */
//   font-feature-settings: "liga";
// }

.pointer,
.pointerBold {
  cursor: pointer;
}

.pointerBold:hover {
  font-weight: bold;
}

.row {
  margin-bottom: 10px;
}

.icon_planimalin {
  background-color: #55b9b0;
  z-index: 10;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
}

.menu {
  .leftMenu {
    position: fixed;
    top: 50px;
    bottom: 0;
    z-index: 100;
  }
  .rightContent {
    margin-left: 70px;
    margin-top: 50px;
    width: 100%;
  }
}

h3,
.h3 {
  font-size: 1.5rem;
}

.filter-header {
  padding: 5px;
}

.card {
  margin-bottom: 10px;
  .card-header {
    padding: 0.15rem 1.25rem;
    background-color: #fcc576;
  }

  .card-body {
    padding: 0.5rem;

    &.limit500 {
      max-height: 500px;
      overflow-y: auto;
    }

    &.limit250 {
      max-height: 250px;
      overflow-y: auto;
    }

    hr {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .card-action {
      display: flex;
      justify-content: flex-end;

      & > * {
        margin: 0 0.25rem;
      }
    }
  }
}
.navbar {
  padding: 0px 0 0 5px;

  .iconePln {
    width: 60px;
  }
}

.line {
  .action {
    display: none;
  }
  &:hover {
    .action {
      display: block;
    }
  }
}
.form-control {
  &.s250 {
    width: 250px;
  }
}
.col_action {
  width: 125px;

  .btn_action {
    display: none;
  }

  &:hover .btn_action {
    display: block;
  }
}

.div_action {
  .btn_action {
    display: none;
  }

  &:hover .btn_action {
    display: block;
  }
}

.sidebar {
  height: 100%;
  background-color: #37474f;

  .sidebar-nav {
    position: relative;
    font-size: 14px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    .sidebar_active{
      background-color: #F5F7FA;
      border-radius: 10px 0px 0px 10px;
    }
    li {
      width: 69px;

      a {
        position: relative;
        display: block;
        padding: 12px 24px;
        color: #515253;
        letter-spacing: 0.025em;
        font-weight: 400;
        text-indent: -3px;
        padding: 10px 0;
        text-align: center;

        img {
          width: 45px;
        }
      }
    }
  }
}

.modal-dialog {
  .modal-header {
    padding: 0.5rem 1rem;
  }

  .modal-body {
    padding-bottom: 0px;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }
  .col-form-label {
    padding-bottom: 0px;
    white-space: nowrap;
  }
  .modal-footer {
    padding: 0.5rem;
  }
}

.margtop {
  margin-top: 5px;
}
.margright {
  margin-right: 5px;
}
.margleft {
  margin-left: 5px;
}
.margbottom {
  margin-bottom: 5px;
}
.bigmargbottom {
  margin-bottom: 20px;
}
.text-bold {
  font-weight: bold;
}

.h3Center {
  line-height: 3rem;
}

.dlg_react_background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparentize($dark-color, 0.3);
  justify-content: space-around;
  z-index: 9999;

  .dlg_react2 {
    background-color: $white-color;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
    color: black;
    padding: 2px;

    .big_title {
      font-weight: bold;
      color: $dark-color;
    }

    .col-form-label {
      padding-bottom: 0;
      white-space: nowrap;
    }
    .dlg_react_titleBar {
      background-color: $secondary-color;
      color: #efefef;
      border-radius: 3px;
      padding: 4px 10px;
      display: flex;
      justify-content: space-between;

      .dlg_react_close {
        cursor: pointer;
        padding: 0px 3px;

        &:hover {
          background-color: $secondary-color;
        }
      }
    }

    .dlg_react_content {
      position: relative;
      border: 0;
      background: none;
      // overflow: auto;

      padding: 15px;
    }

    .dlg_react_action {
      border-width: 1px 0 0 0;
      border-top: solid #c5c5c5 1px;
      background-image: none;
      margin-top: 5px;
      padding: 3px 10px 5px 4px;
      display: flex;
      justify-content: flex-end;

      div:first-child {
        display: flex;
      }
    }
  }
}

.line-datetime-picker{
  display: flex;
  line-height: 115px;
  height: 115px;

  .div-datetime{
    line-height: 75px;
    .libelle{
      line-height: 50px;
      height: 20px;
    }
  }
  .react-datepicker {
    line-height: initial;
  }
}


.login {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden;

  .card_login {
    background-color: #4db6ac !important;
    padding: 0.65rem 1.25rem;
    .logo_login {
      width: 300px;
    }
  }
  .block-center {
    width: 340px;
    margin: 0 auto;
  }
}

.infoAnimal {
  &:hover .action-right {
    display: block;
  }
  .action-right {
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
  }

  .isDCD {
    background-color: #eee;
  }
}

.timePicker {
  display: flex;
  align-items: center;
  .blockHeure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 4em;
  }
  .separatorHeure {
    width: 1em;
    text-align: center;
  }
}

.react-select-header__control {
  min-width: 235px;
}

.form-control {
  &.inTitle {
    height: 40px;
    width: 150px;
  }
}

.limit-height {
  max-height: 250px;
  overflow-y: auto;
}
/********************************** home ***************************************************/
.home {
  .icon-event{
    font-size:40px;
    text-align: center;
    i
    {
      width: 60px;
    }
  }
}


/********************************** dashboard ***************************************************/
.dashboard {
  .table td {
    padding: 0.05rem;
  }
}

/********************************** configuration***********************************************/
.configuration {
  .line_box {
    height: 30px;
    .col_action {
      width: 50px;
      display: none;
    }
    &:hover {
      background-color: #ececec;
      .col_action {
        display: block;
      }
    }
  }
}
/********************************** Invoice ***************************************************/
.invoice {
  .ddl_year{
    max-width: 100px;
  }
  .ddl_month{
    max-width: 150px;
  }
  
  
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  
   .header{ td {
      font-weight: bold;
      border:hidden;
      padding-bottom: 0;

      
    }
   }
    .item {
      td {
      //     padding: 0.75rem;
      // vertical-align: top;
      border: 1px solid #dee2e6;
        background-color: white;
      
        &:first-of-type {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-right: hidden;
        }
      
        &:not(:first-of-type):not(:last-of-type) {
          border-left: hidden;
          border-right: hidden;
        }
      
        &:last-of-type {
          border-left: hidden;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      
        &.iscenter {
          text-align: center;
        }
        &.isdecal{
            text-indent: 5px;
        }
      }
      
      .state {
        border-radius: 5px;
      
        &.state-0 {
          background-color: red;
        }
      
        &.state-1 {
          background-color: green;
        }
      }

    }


    .col-numero{
      width: 70px;
    }
  
    .col-date,.col-montant,.col-acompte {
      width: 130px;
    }
    .col-statut{
      width: 100px;
    } 
    
    .col-action{
      width: 50px;
        }
  }
  
}

.invoice-edit {
  .card-body {
    padding: 1.25rem;
  }

  .col-type {
    width: 150px;
  }

  .col-nombre {
    width: 100px;
  }

  .col-prixU {
    width: 130px;
  }

  .col-remise {
    width: 130px;
  }
  .col-tva {
    width: 130px;
  }
  .col-total {
    width: 150px;
  }

  .col-action {
    width: 50px;
  }
  .item-action {
    padding: 3px;

    .btn {
      font-size: 0.8rem;
      margin: 0px 2px;
    }
  }

  .tr-edit {
    td {
      vertical-align: middle;
    }
  }

  .margright {
    margin-right: 5px;
  }
}

.invoice-detail {
  .infoProprio {
    margin-right: 30px;
  }

  .infoContact {
    margin-right: 30px;
  }

  .infoFacture {
  }

  .blockEntete {
    margin-bottom: 20px;
  }

  .margright {
    margin-right: 5px;
  }

  table {
    border-top: hidden;
    border-bottom: hidden;
    tr:hover {
      background-color: transparent;
    }

    .isdecal {
      text-indent: 10px;
    }

    td {
      border-left: hidden;
      border-right: hidden;
      border-bottom: solid 1px lightgray;

      &.notBottom {
        border-bottom: none;
      }

      &.notTop {
        border-top: none;
      }

      &.backcolored {
        background-color: lightgray;
      }
    }

    .entete {
      td {
        font-weight: bold;
        border-bottom: solid 2px black;
      }
    }

    .lineSepTop {
      td {
        font-weight: bold;
        border-top: solid 2px black;
      }
    }

    .col-libelle {
    }

    .col-type {
      width: 170px;
    }

    .col-nombre {
      width: 170px;
    }

    .col-prixU {
      width: 170px;
    }

    .col-remise {
      width: 170px;
    }
    .col-tva {
      width: 170px;
    }

    .col-total {
      width: 170px;
    }
  }
}

/********************************** Registre ***************************************************/
.registre {
  .col_action div {
    display: flex;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }
  .card-header .form-control {
    max-width: 140px;
  }

  .btn_action {
    cursor: pointer;
    display: none;
  }

  .contentBox {
    margin-left: 10px;
  }

  .contentBox .line_box {
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
  }

  .contentBox .line_box:hover {
    background-color: #fafbfc;
  }

  .contentBox .line_box:hover .btn_action {
    display: inline;
  }

  .pln-btnpdf {
    display: block;
    top: 15px;
    padding-left: 5px;
  }

  table {
    border: solid 2px #aaaaaa;
    border-collapse: collapse;
    border-color: #aaaaaa;
    table-layout: fixed;
    font-size: 10px;
    background: white;
    /*border-spacing: 0px 1px*/
  }

  table td {
    font-size: 10px;
  }

  .entete_soustitre td {
    text-align: center;
    border: solid 1px #aaaaaa;
    background-color: #eef1ff;
  }

  .entete_titre td {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    border: solid 2px #aaaaaa;
  }

  .td-compteur,
  .td-date-entree,
  .td-provenance,
  .td-espece,
  .td-sexe,
  .td-identification,
  .td-desciption,
  .td-date-naissance,
  .td-info-parent,
  .td-date-sortie,
  .td-destination,
  .td-mort {
    border-left: solid 1px #aaaaaa;
    border-right: solid 1px #aaaaaa;
  }

  .td-compteur {
    text-align: center;
    width: 2%;
  }

  .td-date-entree {
    width: 4%;
    text-align: center;
    background-color: #eef1ff !important;
  }

  .td-provenance {
    width: 13%;
    border-right: solid 2px #aaaaaa !important;
  }

  .td-espece {
    width: 3%;
    text-align: center;
  }

  .td-sexe {
    width: 3%;
    text-align: center;
  }

  .td-identification {
    width: 7%;
  }

  .td-desciption {
    width: 13%;
  }

  .td-date-naissance {
    width: 4%;
    text-align: center;
  }

  .td-info-parent {
    width: 16%;
    border-right: solid 2px #aaaaaa !important;
  }

  .td-date-sortie {
    width: 4%;
    text-align: center;
    background-color: #eef1ff !important;
  }

  .td-destination {
    width: 18%;
  }

  .td-mort {
    width: 10%;
  }

  .line-content:nth-of-type(even) {
    background-color: #eeeeee;
  }

  .line-content:nth-of-type(odd) {
    background-color: white;
  }

  .mdl-progress {
    width: calc(100% - 20px);
    margin: 10px;
  }
}
/********************************** planning ***************************************************/
.planning {
  .btn_change_date {
    background-color: #37474f;
    border-radius: 50%;
    text-align: center;
    color: #4db6ac;
    width: 36px;
    height: 36px;
    font-size: 26px;
    margin: 0 5px;
    line-height: 36px;

    &:hover {
      color: #db7464;
    }
  }

  .pln_content {
    overflow-y: scroll;
  }

  .divtopleft {
    border-bottom: solid 1px #cecece;
  }

  .filtre_date {
    display: flex;
    align-items: center;
  }

  .monthInfo {
    border-bottom: solid 1px #cecece;
    text-align: center;
  }

  .timeline_weekend {
    background-color: #e0e0e0;
  }

  .timeline_today {
    background-color: #e0edfb;
  }

  .firstDay {
    border-left: black solid 2px !important;
  }

  @media print {
  }

  .dayItem {
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    z-index: 2;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    background-color: transparent;

    div {
      overflow: hidden;
      text-align: center;
      height: auto;
    }
  }
  .firstColonne {
    border-bottom: solid 1px #cecece;
    display: flex;
    align-items: center;
    line-height: 42px;
    text-indent: 5px;
    .btn-action {
      display: none;
      margin-right: 3px;
    }

    &:hover .btn-action {
      display: block;
    }
  }

  .cell_day {
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    z-index: 2;

    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    background-color: transparent;

    div {
      overflow: hidden;
      text-align: center;
      height: auto;
    }

    &:hover {
      border: solid 1px #55b9b0;
      background-color: #fcc576;
      opacity: 0.5;
    }
  }

  .firstDay {
    border-left: black solid 2px !important;
  }

  .timeline_weekend {
    background-color: #e0e0e0;
  }

  .timeline_today {
    background-color: #e0edfb;
  }

  .line_day {
    overflow: hidden;
  }

  .special_day {
    position: absolute;
    width: 100%;
    margin-left: 0;
    z-index: 1;
  }

  .planning_item {
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    z-index: 5;

    -webkit-box-shadow: 0 0 1px #fff;
    -moz-box-shadow: 0 0 1px #fff;
    box-shadow: 0 0 1px #fff;

    border: 1px solid transparent;
    line-height: 16px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    font-size: 11px;

    &:hover div {
      background: "" repeat-y;
    }
  }
}

/******************************** DIALOG *******************************************************/
.dlg_periode_edit {
  width: 730px;
  height: 480px;
}

.dlg_proprio_edit {
  width: 730px;
}

.dlg_pet_edit {
  width: 730px;
}

.dlg_info_compl_edit {
  width: 750px;
  height: 325px;
}

.dlg_pet_relation {
  .limit-height {
    max-height: 500px;
    overflow-y: auto;
    .div_action {
      width: 75px;
    }
    .table {
      tr {
        height: 30px;
        &:hover {
          .btn_action {
            display: block;
          }
        }
      }

      td {
        padding: 0.05rem;
      }
    }
  }
}

.dlg_box_edit {
  width: 600px;
  height: 50px;
}

.dlg_vaccin_edit {
  width: 600px;
  height: 50px;
}
.dlg_tarif_edit {
  width: 600px;
  height: 175px;
}

.dlg_pension_edit {
  width: 750px;
  height: 750px;
}

.dlg_paiement_edit{
  width: 500px;
  height: 120px;
}

.dlg_contrat_gen{
  width: 700px;  
}